import React from "react";
import HeroFull from "../components/sections/HeroFull";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroFull className="illustration-section-01" />
        <FeaturesTiles className="illustration-section-02" />
        <FeaturesSplit invertMobile topDivider imageFill />
      </React.Fragment>
    );
  }
}

export default Home;
