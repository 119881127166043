import React from "react";
// import section header
// import sections
import Team from "../components/sections/Team";
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
// import some required elements

class OurStory extends React.Component {
  state = {
    demoModalActive: false,
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  };

  render() {
    return (
      <React.Fragment>
        <Team className="illustration-section-03" />
        <GenericSection topDivider className="illustration-section-05">
          <div className="container-xs">
            <h2 className="mt-0">
              We're opinionated about security so you don't have to be
            </h2>
            <p>
              We've built and ran security programs at a global scale. This experience has given us unparalleled insight into the tools and tactics required to respond to threat with immense leverage in seconds.
            </p>
            <p>
              We've distilled these insights into our product, allowing for automated response to most security threats your organization may encounter today. For threats we can't automatically remediate, we'll give you the tools you need to respond instantly.
            </p>
          </div>
        </GenericSection>
        <Cta topDivider className="illustration-section-06" />
      </React.Fragment>
    );
  }
}

// inline style

export default OurStory;
