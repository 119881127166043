import React from "react";
// import section header
import SectionHeader from "../components/sections/partials/SectionHeader";
// import sections
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
// import some required elements

class Security extends React.Component {
  state = {
    demoModalActive: false,
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  };

  render() {
    const pageHeader = {
      title: "Security",
    };

    return (
      <React.Fragment>
        <GenericSection topDivider className="illustration-section-05">
          <SectionHeader data={pageHeader} className="center-content" />
          <div className="container-xs">
            <h2 className="mt-0">Our commitment to data privacy and security</h2>
            <p>
              We believe privacy is a fundamental human right. We believe the
              only way to keep your data private is by keeping your data secure.
            </p>
            <p>
              Our commitment to privacy and data security is reflected in this
              page as well as our Privacy Policy which describes how we collect
              and process personal information that identifies you, like your
              first and last name, your network traffic, your unique device
              identifiers or your email address.
            </p>
            <h2 className="mt-0">Privacy First Design</h2>
            <p>
              All software written at ThreatKey is designed with your privacy
              in mind. We purposefully design our products to best support you,
              the Customer. Your subscription revenue is how we make money. We
              do not and will not sell your data to anyone but you.
            </p>
            <p>
              Where possible, we strive to remove classes of vulnerability, not
              simply find bugs — this includes in the way we think about data
              privacy. In certain instances, in the aim of best protecting user
              privacy, we have limited features or otherwise disabled
              registration. One example is usage of our services by children
              using our site. Presently, we do not allow users under the age of
              13.
            </p>
            <p>
              If your child is under 13 and you believe your child has provided
              us with their personal information, please contact us to have such
              information removed.
            </p>
            <p>
              This was a purposeful decision by our entire team. We did not feel
              we presently had the privacy controls in place to support ensuring
              that all organizations we interface with properly could follow our
              same high level of security scrutiny. Rather than risk failing on
              our promise to you, we disabled the ability for anyone under the
              age of 13 to register.
            </p>
            <h2 className="mt-0">Information Gathering & Use</h2>
            <p>
              ThreatKey strives to continually improve our service for our
              users. To that end, we learn information about you when:
            </p>
            <ul>
              <li>you directly provide it to us</li>
              <li>
                we collect it automatically through our products and services
              </li>
              <li>someone else tells us information about you</li>
            </ul>
            <p>
              We use your information only as you’ve permitted and in order to
              provide you a better user experience.
            </p>
            <p>We use a few services to accomplish this:</p>
            <ul>
              <li>
                Clearbit provides data enrichment to contextualize who you are
              </li>
              <li>Sentry provides application monitoring and error tracking</li>
              <li>
                Netlify provides your content rendering (the site you see) and
                our analytics (which pages you visit and whether or not you were
                successful in viewing them)
              </li>
              <li>
                Drift powers our inbound and outbound customer success
                communications, including the chat options on our site
              </li>
            </ul>
            <h2 className="mt-0">Infrastructure Security</h2>
            <p>
              Our service and company is designed with a secure immutable
              infrastructure in mind.
            </p>
            <ul>
              <li>
                Where possible, all services we use are configured in
                Infrastructure-as-code (Terraform) including our internal
                corporate services (such as GitHub and Okta permissions).
              </li>
              <li>
                We establish clear role-based access controls internally, only
                giving data access to those within the company who explicitly
                need it.
              </li>
              <li>
                All internal sensitive access grants expire by default after 24
                hours, and nobody (even our executives) gets granted superuser
                access.
              </li>
              <li>
                We validate these configurations using a number of tools, but
                primarily utilize Open Policy Agent
              </li>
              <li>
                By default, we tend towards serverless options (still recorded
                in Terraform). We log only the information we absolutely need,
                so serverless makes sense for our business model.
              </li>
              <li>
                In the event of a corporate restructuring or change in our
                organizational structure or status to a successor or affiliate,
                we'll make clear if they deviate from this
              </li>
            </ul>
            <h2 className="mt-0">Data Protection</h2>
            <p>
              ThreatKey implements numerous technical security and privacy
              measures to safeguard your personal information. In the event of a
              security breach, we'll notify you so that you can take appropriate
              protective steps.
            </p>
            <p>
              We only keep your personal information for as long as is needed to
              do what we collected it for. After that, we destroy it unless
              required by law.
            </p>
            <h2 className="mt-0">Data Transit</h2>
            <p>
              As part of our normal operations, your information may be stored
              in computers in other countries outside of your home country. By
              giving us information, you consent to this kind of information
              transfer.
            </p>
            <h2 className="mt-0">EU, EEA, Swiss and California users</h2>
            <p>
              These regions have data protection laws that require us to specify
              the following:
            </p>
            Our lawful bases for collecting and processing personal information
            under the European General Data Protection Regulation (“GDPR”)
            include:
            <ul>
              <li>
                performing our contract with you and providing our services
              </li>
              <li>
                legitimate interests: we receive technical and interaction data
                of users, which may include IP addresses, to improve the
                security and reliability of our services and prevent abuse, and
                to understand where people learn about ThreatKey
              </li>
              <li>
                Consent: where we ask for your consent to process your
                information, you can always withdraw this consent.
              </li>
            </ul>
            <p>
              The following rights are granted under the GDPR and California
              Consumer Privacy Act (“CCPA”). ThreatKey applies these rights to
              all users of our products, regardless of your location:
            </p>
            <ul>
              <li>The right to know what personal data is collected</li>
              <li>
                The right to know if personal data is being shared, and to whom
              </li>
              <li>The right to access your personal data</li>
              <li>
                The right to exercise your privacy rights without being
                discriminated against
              </li>
            </ul>
          </div>
        </GenericSection>
        <Cta topDivider className="illustration-section-06" />
      </React.Fragment>
    );
  }
}

// inline style

export default Security;
