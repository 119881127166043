import React from "react";
// import section header
import SectionHeader from "../components/sections/partials/SectionHeader";
// import sections
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
// import some required elements

class VRP extends React.Component {
  state = {
    demoModalActive: false,
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  };

  render() {
    const pageHeader = {
      title: "Vulnerability Rewards Program",
    };

    return (
      <React.Fragment>
        <GenericSection topDivider className="illustration-section-05">
          <SectionHeader data={pageHeader} className="center-content" />
          <div className="container-xs">
            <h2 className="mt-0">Security is in our DNA</h2>
            <p>We strive for the deepest layer of security at every level.</p>
            <p>
              If you believe you have found a security vulnerability in our
              product, services, or online presence, please let us know at
              security@threatkey.com
            </p>
            <p>
              Testing is only authorized on the targets listed as In-Scope
              (app.threatkey.com, www.threatkey.com, and api.threatkey.com).
            </p>
            <p>
              Any domain/property of ThreatKey not listed in the targets
              section is out of scope. This includes any/all subdomains not
              listed above.
            </p>
          </div>
        </GenericSection>
        <Cta topDivider className="illustration-section-06" />
      </React.Fragment>
    );
  }
}

// inline style

export default VRP;
