import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';

// Views
import Home from './views/Home';
import OurStory from './views/OurStory';
import PrivacyPolicy from './views/PrivacyPolicy';
import TermsOfService from './views/TermsOfService';
import Careers from './views/Careers';
import Press from './views/Press';
import VRP from './views/VRP';
import Security from './views/Security';
import PricingView from './views/PricingView';

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/our-story" component={OurStory} layout={LayoutAlternative} />
            <AppRoute exact path="/careers" component={Careers} layout={LayoutAlternative} />
            <AppRoute exact path="/security" component={Security} layout={LayoutAlternative} />
            <AppRoute exact path="/press" component={Press} layout= {LayoutAlternative} />
            <AppRoute exact path="/vrp" component={VRP} layout={LayoutAlternative} />
            <AppRoute exact path="/privacy-policy" component={PrivacyPolicy} layout={LayoutAlternative} />
            <AppRoute exact path="/terms-of-service" component={TermsOfService} layout={LayoutAlternative} />
            <AppRoute exact path="/pricing" component={PricingView} layout={LayoutAlternative} />
          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);
