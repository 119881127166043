import React from "react";
// import section header
import SectionHeader from "../components/sections/partials/SectionHeader";
// import sections
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
// import some required elements

class Press extends React.Component {
  state = {
    demoModalActive: false,
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  };

  render() {
    const pageHeader = {
      title: "Press",
    };

    return (
      <React.Fragment>
        <GenericSection topDivider className="illustration-section-05">
          <SectionHeader data={pageHeader} className="center-content" />
          <div className="container-xs">
            <h2 className="mt-0">Press Kit</h2>
            <p>
              <ul>
                <li>Logo (SVG)</li>
                <li>One Pager (PDF)</li>
              </ul>
            </p>

            <h2 className="mt-0">Contact Us</h2>
            <p>
              You can get in touch by emailing us at press@threatkey.com or by
              mailing us at:
            </p>
            <p>ThreatKey, Inc.</p>
            <p>Attn: Press</p>
            <p>228 Park Ave S</p>
            <p>PMB 52129</p>
            <p>New York, NY 10003</p>
          </div>
        </GenericSection>
        <Cta topDivider className="illustration-section-06" />
      </React.Fragment>
    );
  }
}

// inline style

export default Press;
