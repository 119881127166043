import React from "react";
// import section header
import SectionHeader from "../components/sections/partials/SectionHeader";
// import sections
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
// import some required elements

class Careers extends React.Component {

  render() {
    const pageHeader = {
      title: "Careers",
    };

    return (
      <React.Fragment>
        <GenericSection topDivider className="illustration-section-05">
          <SectionHeader data={pageHeader} className="center-content" />
          <div className="container-xs">
            <p>
              Interested in joining our team? Please email us at
              jobs@threatkey.com and we’ll be in touch!
            </p>
          </div>
        </GenericSection>
        <Cta topDivider className="illustration-section-06" />
      </React.Fragment>
    );
  }
}

// inline style

export default Careers;
