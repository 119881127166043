import React from "react";
// import section header
import SectionHeader from "../components/sections/partials/SectionHeader";
// import sections
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
// import some required elements

class TermsOfService extends React.Component {

    render() {
        const pageHeader = {
            title: "Terms of Service",
        };

        return (
            <React.Fragment>
                <GenericSection topDivider className="illustration-section-05">
                    <SectionHeader data={pageHeader} className="center-content" />
                    <div className="container-xs">
                        <h1 id="threatkey-terms-of-service">ThreatKey Terms of Service</h1>
<p>Last Updated: 2021-11-29</p>
<p>Welcome, and thank you for your interest in ThreatKey, Inc. (“ThreatKey,” “we,” or “us”) and our website at www.threatkey.com, along with our related websites, hosted applications, and other services provided by us (collectively, the “Service”). These Terms of Service are a legally binding contract between you and ThreatKey regarding your use of the Service.</p>
<p><strong>PLEASE READ THE FOLLOWING TERMS CAREFULLY:</strong></p>
<p><strong>BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICE</strong>, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING THREATKEY’S <a href="https://threatkey.com/privacy-policy/">PRIVACY POLICY</a> (TOGETHER, THESE “TERMS”). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. YOUR USE OF THE SERVICE, AND THREATKEY’S PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY THREATKEY AND BY YOU TO BE BOUND BY THESE TERMS.</p>
<p><strong>ARBITRATION NOTICE.</strong> Except for certain kinds of disputes described in Section 17, you agree that disputes arising under these Terms will be resolved by binding, individual arbitration, and BY ACCEPTING THESE TERMS, YOU AND THREATKEY ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING.</p>
<p>1 <strong>ThreatKey Service Overview.</strong> The Service consists of a software-as-a-service product that connects other software-as-a-service security products. The Service integrates with third-party tools to enable automated workflow, installation and configuration of updates for cloud services, mitigations of security threats, provide a security assessment overview, and reports the same to your customers.</p>
<p>1.1 <strong>Access Credentials.</strong> We may provide you with the opportunity to import your data to third-party tools for use within the Service.  You need to provide us with your login credentials or other equivalent security credentials or keys for the third-party tools in order to access such third-party tools on your behalf (“Access Credentials”).  You acknowledge and agree to provide us such Access Credentials and we may use such Access Credentials in order to provide the Service. You represent and warrant that you have the necessary rights and authorizations to provide us with such Access Credentials and to process any data from third-party tools. You are solely responsible for ensuring that you have the proper rights, including applicable individual consents, prior to transferring data to ThreatKey and for maintaining the confidentiality of your Access Credentials, and you accept responsibility for all activities that occur under your account.</p>
<p>1.2 <strong>Data Processing.</strong> In providing the Service, ThreatKey will: (i) receive your Access Credentials; and (ii) access the metadata of documents stored in the your third party tools which the Service has access to in connection with your Access Credentials (including metadata showing how your employees interact with these third party tools, the name of documents and files, and when, where, and which employees viewed, opened or shared documents and files (and with whom) (collectively, “Your Information”). ThreatKey will not: (i) retain, use, or disclose Your Information for any purpose other than for the specific purpose of providing the Service specified in these Terms; or (ii) sell Your Information. Notwithstanding the foregoing, ThreatKey is permitted to process Your Information for purposes permitted by applicable law, including for internal use to improve the Service, to detect security incidents and vulnerabilities, and protect against fraudulent or illegal activity.</p>
<p>2 <strong>Eligibility.</strong> You must be at least 18-years old to use the Service. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 18-years old; (b) you have not previously been suspended or removed from the Service; and (c) your registration and your use of the Service is in compliance with any and all applicable laws and regulations. If you are an entity, organization, or company, the individual accepting these Terms on your behalf represents and warrants that they have authority to bind you to these Terms and you agree to be bound by these Terms. </p>
<p>3 <strong>Accounts and Registration.</strong> To access most features of the Service, you must register for an account. When you register for an account, you may be required to provide us with some information about yourself, such as your name, email address, county or state, or other contact information. You agree that the information you provide to us is accurate, complete, and not misleading, and that you will keep it accurate and up to date at all times. When you register, you will be asked to create a password. You are solely responsible for maintaining the confidentiality of your account and password, and you accept responsibility for all activities that occur under your account. If you believe that your account is no longer secure, then you should immediately notify us at <a href="mailto:support@threatkey.com">support@threatkey.com</a>.</p>
<p>4 <strong>General Payment Terms.</strong> Certain features of the Service may require you to pay fees. Before you pay any fees, you will have an opportunity to review and accept the fees that you will be charged. All fees are in U.S. Dollars and are non-refundable unless otherwise specifically provided for in these Terms.</p>
<p>4.1 <strong>Price.</strong> ThreatKey reserves the right to determine pricing for the Service. ThreatKey will make reasonable efforts to keep pricing information published on the Service up to date. We encourage you to check our pricing page periodically for current pricing information at <a href="https://threatkey.com/pricing/">https://threatkey.com/pricing/</a>. ThreatKey may change the fees for any feature of the Service, including additional fees or charges, if ThreatKey gives you advance notice of changes before they apply. ThreatKey, at its sole discretion, may make promotional offers with different features and different pricing to any of ThreatKey’s customers. These promotional offers, unless made to you, will not apply to your offer or these Terms.</p>
<p>4.2 <strong>Authorization.</strong> You authorize ThreatKey to charge all sums for the orders that you make and any level of Service you select as described in these Terms or published by ThreatKey, including all applicable taxes, to the payment method specified in your account. If you pay any fees with a credit card, then ThreatKey may seek pre-authorization of your credit card account prior to your purchase to verify that the credit card is valid and has the necessary funds or credit available to cover your purchase.</p>
<p>4.3 <strong>Subscription Service.</strong> The Service may include certain subscription-based plans with automatically recurring payments for periodic charges (“Subscription Service”). The “Subscription Billing Date” is the date when you purchase your first subscription to the Service. The Subscription Service will begin on the Subscription Billing Date and continue for the subscription period that you select on your account (such period, the “Initial Subscription Period”), and will automatically renew for successive periods of the same duration as the Initial Subscription Period (the Initial Subscription Period and each such renewal period, each a “Subscription Period”) unless you cancel the Subscription Service or we terminate it. If you activate a Subscription Service, then you authorize ThreatKey or its third-party payment processors to periodically charge, on a going-forward basis and until cancellation of the Subscription Service, all accrued sums on or before the payment due date. For information on the “Subscription Fee,” please see our Pricing page at threatkey.com/pricing/. Your account will be charged automatically on the Subscription Billing Date and thereafter on the renewal date of your Subscription Service for all applicable fees and taxes for the next Subscription Period. You must cancel your Subscription Service before it renews in order to avoid billing of the next periodic Subscription Fee to your account. ThreatKey or its third-party payment processor will bill the periodic Subscription Fee to the payment method associated with your account or that you otherwise provide to us. You may cancel the Subscription Service by contacting us at <a href="mailto:support@threatkey.com">support@threatkey.com</a>. YOUR CANCELLATION MUST BE RECEIVED BEFORE THE RENEWAL DATE IN ORDER TO AVOID CHARGE FOR THE NEXT SUBSCRIPTION PERIOD.</p>
<p>4.4 <strong>Delinquent Accounts.</strong> ThreatKey may suspend or terminate access to the Service, including fee-based portions of the Service, for any account for which any amount is due but unpaid. In addition to the amount due for the Service, a delinquent account will be charged with fees or charges that are incidental to any chargeback or collection of any unpaid amount, including collection fees. If your payment method is no longer valid at the time a renewal Subscription Fee is due, then ThreatKey reserves the right to delete your account and any information or User Content (defined below) associated with your account without any liability to you. </p>
<p>4.5 <strong>Free Trial.</strong> ThreatKey reserves the right to provide any or part of the Service on a free trial basis. If you register for the Service for a free trial, ThreatKey will make one or more Services available to you on a trial basis free of charge until the earlier of (a) the end of the free trial period for which you registered to use the applicable Service, or (b) the start date of any Subscription Service ordered by you. Additional trial terms and conditions may appear on the trial registration web page. Any such additional terms and conditions are incorporated into these Terms by reference and are legally binding. ANY CUSTOMIZATIONS MADE TO THE SERVICE BY OR FOR YOU, DURING YOUR FREE TRIAL WILL BE PERMANENTLY LOST UNLESS YOU PURCHASE A SUBSCRIPTION TO THE SAME SERVICES AS THOSE COVERED BY THE TRIAL, PURCHASE UPGRADED SERVICES, OR EXPORT SUCH DATA, BEFORE THE END OF THE TRIAL PERIOD. DURING THE FREE TRIAL, THE SERVICE IS PROVIDED “AS IS” WITHOUT ANY WARRANTY.</p>
<p>5 <strong>Licenses</strong>
5.1 <strong>Limited License.</strong> Subject to your complete and ongoing compliance with these Terms, ThreatKey grants you, solely for your personal, non-commercial use, a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the Service. </p>
<p>5.2 <strong>License Restrictions.</strong> Except and solely to the extent such a restriction is impermissible under applicable law, you may not: (a) reproduce, distribute, publicly display, publicly perform, or create derivative works of the Service; (b) make modifications to the Service; or (c) interfere with or circumvent any feature of the Service, including any security or access control mechanism. If you are prohibited under applicable law from using the Service, then you may not use it.</p>
<p>5.3 <strong>Feedback.</strong> ThreatKey respects and appreciates the thoughts and comments from its users.  If you choose to provide input and suggestions regarding existing functionalities, problems with or proposed modifications or improvements to the Service (“Feedback”), then you hereby grant ThreatKey an unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right and license to exploit the Feedback in any manner and for any purpose, including to improve the Service and create other products and services. ThreatKey will have no obligation to provide you with attribution for any Feedback you provide to us.</p>
<p>6 <strong>Ownership; Proprietary Rights.</strong> The Service is owned and operated by ThreatKey. The visual interfaces, graphics, design, compilation, information, data, computer code (including source code or object code), products, software, services, and all other elements of the Service provided by ThreatKey (“Materials”) are protected by intellectual property and other laws. All Materials included in the Service are the property of ThreatKey or its third-party licensors. Except as expressly authorized by ThreatKey, you may not make use of the Materials. There are no implied licenses in these Terms and ThreatKey reserves all rights to the Materials not granted expressly in these Terms.</p>
<p>7 Third-Party Terms</p>
<p>7.1 <strong>Third-Party Services and Linked Websites.</strong> ThreatKey may provide tools through the Service that enable you to export information, including User Content, to third-party services, including through features that allow you to link your account on the Service with an account on the third-party service, such as Twitter or Facebook, or through our implementation of third-party buttons (such as “like” or “share” buttons). By using one of these tools, you hereby authorize ThreatKey to transfer that information to the applicable third party service. Third-party services are not under ThreatKey’s control, and, to the fullest extent permitted by law, ThreatKey is not responsible for any third-party service’s use of your exported information. The Service may also contain links to third-party websites. Linked websites are not under ThreatKey’s control, and ThreatKey is not responsible for their content. Please be sure to review the terms of use and privacy policy of any third-party services before you share any User Content or information with such third-party services. Once sharing occurs, ThreatKey will have no control over the information that has been shared.</p>
<p>7.2 <strong>Third-Party Software.</strong> The Service may include or incorporate third-party software components that are generally available free of charge under licenses granting recipients broad rights to copy, modify, and distribute those components (“Third-Party Components”). Although the Service is provided to you subject to these Terms, nothing in these Terms prevents, restricts, or is intended to prevent or restrict you from obtaining Third-Party Components under the applicable third-party licenses or to limit your use of Third-Party Components under those third-party licenses.</p>
<p>8 <strong>User Content</strong></p>
<p>8.1 <strong>User Content Generally.</strong> Certain features of the Service may permit users to submit, upload, publish, broadcast, or otherwise transmit (“Post”) content to the Service, including messages, images, folders, data, text, and any other works of authorship or other works (“User Content”). You retain any copyright and other proprietary rights that you may hold in the User Content that you Post to the Service, subject to the licenses granted in these Terms.</p>
<p>8.2 <strong>Limited License Grant to ThreatKey.</strong> By Posting User Content to or via the Service, you grant ThreatKey a worldwide, non-exclusive, irrevocable, royalty-free, fully paid right and license (with the right to sublicense through multiple tiers) to host, store, transfer, publicly display, publicly perform (including by means of a digital audio transmission), communicate to the public, reproduce, modify for the purpose of formatting for display, create derivative works as authorized in these Terms, and distribute your User Content, in whole or in part, in any media formats and through any media channels, in each instance whether now known or hereafter developed. You agree to pay all monies owing to any person or entity resulting from Posting your User Content and from ThreatKey’s exercise of the license set forth in this Section.</p>
<p>8.3 <strong>You Must Have Rights to the Content You Post; User Content Representations and Warranties.</strong> You must not Post User Content if you are not the owner of or are not fully authorized to grant rights in all of the elements of that User Content. ThreatKey disclaims any and all liability in connection with User Content. You are solely responsible for your User Content and the consequences of providing User Content via the Service. By providing User Content via the Service, you affirm, represent, and warrant to us that:</p>
<ul>
<li>you are the creator and owner of the User Content, or have the necessary licenses, rights, consents, and permissions to authorize ThreatKey and users of the Service to use and distribute your User Content as necessary to exercise the licenses granted by you in this Section, in the manner contemplated by ThreatKey, the Service, and these Terms;</li>
<li>your User Content, and the Posting or other use of your User Content as contemplated by these Terms, does not and will not: (i) infringe, violate, misappropriate, or otherwise breach any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property, contract, or proprietary right; (ii) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person; or (iii) cause ThreatKey to violate any law or regulation or require us to obtain any further licenses from or pay any royalties, fees, compensation or other amounts or provide any attribution to any third parties; and</li>
<li>your User Content could not be deemed by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.</li>
</ul>
<p>8.4 <strong>User Content Disclaimer.</strong> ThreatKey is under no obligation to edit or control User Content that you or other users Post and will not be in any way responsible or liable for User Content. ThreatKey may, however, at any time and without prior notice, screen, remove, edit, or block any User Content that in our sole judgment violates these Terms, is alleged to violate the rights of third parties, or is otherwise objectionable. You understand that, when using the Service, you will be exposed to User Content from a variety of sources and acknowledge that User Content may be inaccurate, offensive, indecent, or objectionable. You agree to waive, and do waive, any legal or equitable right or remedy you have or may have against ThreatKey with respect to User Content. If notified by a user or content owner that User Content allegedly does not conform to these Terms, ThreatKey may investigate the allegation and determine in our sole discretion whether to remove the User Content, which it reserves the right to do at any time and without notice. For clarity, ThreatKey does not permit infringing activities on the Service.</p>
<p>8.5 <strong>Monitoring Content.</strong> ThreatKey does not control and does not have any obligation to monitor: (a) User Content; (b) any content made available by third parties; or (c) the use of the Service by its users. You acknowledge and agree that ThreatKey reserves the right to, and may from time to time, monitor any and all information transmitted or received through the Service for operational and other purposes. If at any time ThreatKey chooses to monitor the content, then ThreatKey still assumes no responsibility or liability for content or any loss or damage incurred as a result of the use of content. During monitoring, information may be examined, recorded, copied, and used in accordance with our <a href="https://threatkey.com/privacy-policy/">Privacy Policy</a>. ThreatKey may block, filter, mute, remove or disable access to any User Content uploaded to or transmitted through the Service without any liability to the user who Posted such User Content to the Service or to any other users of the Service.</p>
<p>9 <strong>Communications</strong></p>
<p>9.1 <strong>Text Messaging.</strong> You agree that ThreatKey and those acting on our behalf may send you text (SMS) messages at the phone number you provide us. These messages may include operational messages about your use of the Service, as well as marketing messages. Text messages may be sent using an automatic telephone dialing system. Standard data and message rates may apply whenever you send or receive such messages, as specified by your carrier. When you elect to receive text messages from ThreatKey or those acting on our behalf, you will be given instructions for opting out of receiving text messages. You may continue to receive text messages for a short period while we process your request, including a message confirming the receipt of your opt-out request. Your agreement to receive marketing texts is not a condition of any purchase on or use of the Service.</p>
<p>9.2 <strong>Email.</strong> ThreatKey may send you emails concerning our products and services, as well as those of third parties. You may opt out of promotional emails by following the unsubscribe instructions in the promotional email itself.</p>
<p>10 <strong>Prohibited Conduct</strong> BY USING THE SERVICE, YOU AGREE NOT TO:</p>
<p>10.1 use the Service for any illegal purpose or in violation of any local, state, national, or international law;</p>
<p>10.2 violate, encourage others to violate, or provide instructions on how to violate, any right of a third party, including by infringing or misappropriating any third-party intellectual property right;</p>
<p>10.3 access, search, or otherwise use any portion of the Service through the use of any engine, software, tool, agent, device, or mechanism (including spiders, robots, crawlers, and data mining tools) other than the software or search agents provided by ThreatKey;</p>
<p>10.4 interfere with security-related features of the Service, including by: (i) disabling or circumventing features that prevent or limit use, printing or copying of any content; or (ii) reverse engineering or otherwise attempting to discover the source code of any portion of the Service except to the extent that the activity is expressly permitted by applicable law;</p>
<p>10.5 interfere with the operation of the Service or any user’s enjoyment of the Service, including by: (i) uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code; (ii) making any unsolicited offer or advertisement to another user of the Service; (iii) collecting personal information about another user or third party without consent; or (iv) interfering with or disrupting any network, equipment, or server connected to or used to provide the Service;</p>
<p>10.6 perform any fraudulent activity including impersonating any person or entity, claiming a false affiliation or identity, accessing any other Service account without permission;</p>
<p>10.7 sell or otherwise transfer the access granted under these Terms or any Materials (as defined in Section 6) or any right or ability to view, access, or use any Materials; or</p>
<p>10.8 attempt to do any of the acts described in this Section 10 or assist or permit any person in engaging in any of the acts described in this Section 10.</p>
<p>11 <strong>Compliance with Laws.</strong> You will comply with all laws in your use of the software and/or Services, including any applicable export and sanctions laws. You represent and warrant that you are not located in a country or territory that is subject to economic sanctions or trade embargoes imposed by the U.S. Government (including Cuba, Iran, North Korea, Syria, and the Crimea region of Ukraine), that you are not listed or identified on any U.S. Government list of sanctioned parties, and that you will comply fully with all applicable export laws and regulations of the United States and other export and sanctions laws applicable to your use of the software and/or Services, which include restrictions on destinations, end users, and end use. ThreatKey reserves the right to terminate your access to the software and/or Services if you engage in activities that violate these laws.</p>
<p>12 <strong>Modification of Terms.</strong> ThreatKey may, from time to time, change these Terms. Please check these Terms periodically for changes. Revisions will be effective immediately except that, for existing users, material revisions will be effective 30 days after posting or notice to you of the revisions unless otherwise stated.  If you do not agree to the modified Terms, then you should discontinue your use of the Service. Except as expressly permitted in this Section 11, these Terms may be amended only by a written agreement signed by authorized representatives of the parties to these Terms. </p>
<p>13 <strong>Term, Termination, and Modification of the Service</strong></p>
<p>13.1 <strong>Term.</strong> These Terms are effective beginning when you accept the Terms or first download, install, access, or use the Service, and ending when terminated as described in Section 13.2.</p>
<p>13.2 <strong>Termination.</strong> If you violate any provision of these Terms, then your authorization to access the Service and these Terms automatically terminates. In addition, ThreatKey may, at its sole discretion, terminate these Terms or your account on the Service, or suspend or terminate your access to the Service, at any time for any reason or no reason, with or without notice, and without any liability to you arising from such termination; provided that ThreatKey will issue you with a pro-rata refund of the Subscription Fee paid in advance by you for the Subscription Services during the Subscription Period after the effective date of termination. You may terminate your account and these Terms at any time by contacting customer service at <a href="mailto:support@threatkey.com">support@threatkey.com</a>.</p>
<p>13.3 <strong>Effect of Termination.</strong> Upon termination of these Terms: (a) your license rights will terminate and you must immediately cease all use of the Service; (b) you will no longer be authorized to access your account or the Service; (c) you must pay ThreatKey any unpaid amount that was due prior to termination; and (d) all payment obligations accrued prior to termination and Sections 5.3, 6, 13.3, 14, 15, 16, 17, and 18 will survive. You are solely responsible for retaining copies of any User Content you Post to the Service since upon termination of your account, you may lose access rights to any User Content you Posted to the Service. If your account has been terminated for a breach of these Terms, then you are prohibited from creating a new account on the Service using a different name, email address or other forms of account verification.</p>
<p>13.4 <strong>Modification of the Service.</strong> ThreatKey reserves the right to modify or discontinue all or any portion of the Service at any time (including by limiting or discontinuing certain features of the Service), temporarily or permanently, without notice to you. ThreatKey will have no liability for any change to the Service, including any paid-for functionalities of the Service, or any suspension or termination of your access to or use of the Service; provided that you may terminate as described in Section 13.2. You should retain copies of any User Content you Post to the Service so that you have permanent copies in the event the Service is modified in such a way that you lose access to User Content you Posted to the Service.</p>
<p>14 <strong>Indemnity.</strong> To the fullest extent permitted by law, you are responsible for your use of the Service, and you will defend and indemnify ThreatKey, its affiliates and their respective shareholders, directors, managers, members, officers, employees, consultants, and agents (together, the “ ThreatKey Entities”) from and against every claim brought by a third party, and any related liability, damage, loss, and expense, including attorneys’ fees and costs, arising out of or connected with: (1) your unauthorized use of, or misuse of, the Service; (2) your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation; (3) your violation of any third party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (4) any dispute or issue between you and any third party including such third party’s reliance on the Service. ThreatKey reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with its defense of those claims. </p>
<p>15 <strong>Disclaimers; No Warranties by ThreatKey</strong></p>
<p>15.1 THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. THREATKEY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING: (a) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (b) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. THREATKEY DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND THREATKEY DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED. THE SERVICE WILL NOT BE ABLE TO DETECT OR CORRECT ALL SECURITY VULNERABILITIES, THREATS, OR OTHER SECURITY ISSUES. ANY RELIANCE ON THE SERVICE AND ANY ACTION TAKEN OR NOT TAKEN AS A RESULT OF THE SERVICE IS AT CUSTOMER’S SOLE RISK.</p>
<p>15.2 NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR THREATKEY ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE THREATKEY ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. THREATKEY IS NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT THREATKEY IS NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF DATA, INCLUDING USER CONTENT.</p>
<p>15.3 THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION 15 APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. ThreatKey does not disclaim any warranty or other right that ThreatKey is prohibited from disclaiming under applicable law.</p>
<p>16 <strong>Limitation of Liability</strong></p>
<p>16.1 TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE THREATKEY ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY THREATKEY ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.</p>
<p>16.2 EXCEPT AS PROVIDED IN SECTIONS 17.5 AND 17.6 AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE THREATKEY ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (a) THE AMOUNT YOU HAVE PAID TO THREATKEY FOR ACCESS TO AND USE OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO THE CLAIM AND (b) 100 U.S. DOLLARS.</p>
<p>16.3 EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 16 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p>
<p>17 <strong>Dispute Resolution and Arbitration</strong></p>
<p>17.1 <strong>Generally.</strong> In the interest of resolving disputes between you and ThreatKey in the most expedient and cost effective manner, and except as described in Section 17.2 and 17.3, you and ThreatKey agree that every dispute arising in connection with these Terms, the Service, and communications from ThreatKey will be resolved by binding arbitration. Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. This agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of these Terms, the Service, or any communications to or from us, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND THREATKEY ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</p>
<p>17.2 <strong>Exceptions.</strong> Despite the provisions of Section 17.1, nothing in these Terms will be deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable federal, state, or local agency if that action is available; (c) seek injunctive relief in a court of law in aid of arbitration; or (d) to file suit in a court of law to address an intellectual property infringement claim.</p>
<p>17.3 <strong>Opt-Out.</strong> If you do not wish to resolve disputes by binding arbitration, you may opt out of the provisions of this Section 17 within 30 days after the date that you agree to these Terms by sending a letter to ThreatKey, Inc., Attention: Legal Department Arbitration Opt-Out, 228 Park Avenue South, PMB 52129, New York, New York 10003-1502 that specifies: your full legal name, the email address associated with your account on the Service, and a statement that you wish to opt out of arbitration (“Opt-Out Notice”). Once ThreatKey receives your Opt-Out Notice, this Section 17 will be void and any action arising out of these Terms will be resolved as set forth in Section 18.2. The remaining provisions of these Terms will not be affected by your Opt-Out Notice.</p>
<p>17.4 <strong>Arbitrator.</strong> Any arbitration between you and ThreatKey will be settled under the Federal Arbitration Act and administered by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (collectively, “AAA Rules”) as modified by these Terms. The AAA Rules and filing forms are available online at www.adr.org, by calling the AAA at +1-800-778-7879, or by contacting ThreatKey. The arbitrator has exclusive authority to resolve any dispute relating to the interpretation, applicability, or enforceability of this binding arbitration agreement.</p>
<p>17.5 <strong>Notice of Arbitration; Process.</strong> A party who intends to seek arbitration must first send a written notice of the dispute to the other party by certified U.S. Mail or by Federal Express (signature required) or, only if that other party has not provided a current physical address, then by electronic mail (“Notice of Arbitration”). ThreatKey’s address for Notice of Arbitration is: ThreatKey, Inc., 228 Park Avenue South, PMB 52129, New York, New York 10003-1502. The Notice of Arbitration must: (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (“Demand”). The parties will make good faith efforts to resolve the claim directly, but if the parties do not reach an agreement to do so within 30 days after the Notice of Arbitration is received, you or ThreatKey may commence an arbitration proceeding. All arbitration proceedings between the parties will be confidential unless otherwise agreed by the parties in writing. During the arbitration, the amount of any settlement offer made by you or ThreatKey must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. If the arbitrator awards you an amount higher than the last written settlement amount offered by ThreatKey in settlement of the dispute prior to the award, ThreatKey will pay to you the higher of: (a) the amount awarded by the arbitrator and (b) 10,000 US dollars. </p>
<p>17.6 <strong>Fees.</strong> If you commence arbitration in accordance with these Terms, ThreatKey will reimburse you for your payment of the filing fee, unless your claim is for more than 10,000 U.S. Dollars, in which case the payment of any fees will be decided by the AAA Rules. Any arbitration hearing will take place at a location to be agreed upon in the county and state of your billing address, but if the claim is for 10,000 U.S. Dollars or less, you may choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a non-appearance based telephone hearing; or (c) by an in-person hearing as established by the AAA Rules in the county (or parish) of your billing address. If the arbitrator finds that either the substance of your claim or the relief sought in the Demand is frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all fees will be governed by the AAA Rules. In that case, you agree to reimburse ThreatKey for all monies previously disbursed by it that are otherwise your obligation to pay under the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any time during the proceeding and upon request from either party made within 14 days of the arbitrator’s ruling on the merits.</p>
<p>17.7 <strong>No Class Actions.</strong> YOU AND THREATKEY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and ThreatKey agree otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or class proceeding.</p>
<p>17.8 <strong>Modifications to this Arbitration Provision.</strong> If ThreatKey makes any future change to this arbitration provision, other than a change to ThreatKey’s address for Notice of Arbitration, you may reject the change by sending ThreatKey written notice within 30 days of the change to ThreatKey’s address for Notice of Arbitration, in which case your account with ThreatKey will be immediately terminated and this arbitration provision, as in effect immediately prior to the changes you rejected will survive.</p>
<p>17.9 <strong>Enforceability.</strong> If Section 17.7 or the entirety of this Section 17 is found to be unenforceable, or if ThreatKey receives an Opt-Out Notice from you, then the entirety of this Section 17 will be null and void and, in that case, the exclusive jurisdiction and venue described in Section 18.2 will govern any action arising out of or related to these Terms.</p>
<p>18 <strong>Miscellaneous</strong></p>
<p>18.1 <strong>General Terms.</strong> These Terms, including the Privacy Policy and any other agreements expressly incorporated by reference into these Terms, are the entire and exclusive understanding and agreement between you and ThreatKey regarding your use of the Service. You may not assign or transfer these Terms or your rights under these Terms, in whole or in part, by operation of law or otherwise, without our prior written consent. ThreatKey may assign these Terms and all rights granted under these Terms, including with respect to your User Content, at any time without notice or consent. The failure to require performance of any provision will not affect ThreatKey’s right to require performance at any other time after that, nor will a waiver by us of any breach or default of these Terms, or any provision of these Terms, be a waiver of any subsequent breach or default or a waiver of the provision itself. Use of Section headers in these Terms is for convenience only and will not have any impact on the interpretation of any provision. Throughout these Terms the use of the word “including” means “including but not limited to.” If any part of these Terms is held to be invalid or unenforceable, then the unenforceable part will be given effect to the greatest extent possible, and the remaining parts will remain in full force and effect.</p>
<p>18.2 <strong>Governing Law.</strong> These Terms are governed by the laws of the State of New York without regard to conflict of law principles. You and ThreatKey submit to the personal and exclusive jurisdiction of the state courts and federal courts located within New York, New York for resolution of any lawsuit or court proceeding permitted under these Terms. ThreatKey operates the Service from its offices in New York, and it makes no representation that Materials included in the Service are appropriate or available for use in other locations.</p>
<p>18.3 <strong>Privacy Policy.</strong> Please read the ThreatKey <a href="https://threatkey.com/privacy-policy/">Privacy Policy</a> (the “Privacy Policy”) carefully for information relating to our collection, use, storage, and disclosure of your personal information. The ThreatKey Privacy Policy is incorporated by this reference into, and made a part of, these Terms.</p>
<p>18.4 <strong>Additional Terms.</strong> Your use of the Service is subject to all additional terms, policies, rules, or guidelines applicable to the Service or certain features of the Service that we may post on or link to from the Service (the “Additional Terms”). All Additional Terms are incorporated by this reference into, and made a part of, these Terms.</p>
<p>18.5 <strong>Consent to Electronic Communications.</strong> By using the Service, you consent to receiving certain electronic communications from ThreatKey as further described in our Privacy Policy. Please read our Privacy Policy to learn more about our electronic communications practices. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that those communications be in writing.</p>
<p>18.6 <strong>Contact Information.</strong> The Service is offered by ThreatKey, Inc., located at 228 Park Avenue South, PMB 52129, New York, New York 10003-1502. You may contact us by sending correspondence to that address.</p>
<p>18.7 <strong>Notice to California Residents.</strong> If you are a California resident, then under California Civil Code Section 1789.3, you may contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at +1-800-952-5210 in order to resolve a complaint regarding the Service or to receive further information regarding use of the Service.</p>
<p>18.8 <strong>No Support.</strong> ThreatKey is under no obligation to provide support for the Service. In instances where we may offer support, the support will be subject to published policies.</p>
<p>18.9 <strong>International Use.</strong> The Service is intended for visitors located within the United States. We make no representation that the Service is appropriate or available for use outside of the United States. Access to the Service from countries or territories or by individuals where such access is illegal is prohibited.</p>
                    </div>
                </GenericSection>
                <Cta topDivider className="illustration-section-06" />
            </React.Fragment>
        );
    }
}

// inline style

export default TermsOfService;
