import React from "react";
// import section header
import SectionHeader from "../components/sections/partials/SectionHeader";
// import sections
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
// import some required elements

class PrivacyPolicy extends React.Component {

  render() {
    const pageHeader = {
      title: "Privacy Policy",
    };

    return (
      <React.Fragment>
        <GenericSection topDivider className="illustration-section-05">
          <SectionHeader data={pageHeader} className="center-content" />
          <div className="container-xs">
            <h1 id="threatkey-privacy-policy">ThreatKey Privacy Policy</h1>
<p>Last Updated: 2021-11-29</p>
<p>ThreatKey, Inc. (“ThreatKey,” “we,” “our,” and/or “us”) values the privacy of individuals who use our website, web portal, and related online services (collectively, our “Services”). This privacy policy (the “Privacy Policy”) explains how we collect, use, and share information from users of our Services (“Users”, “you”, and/or “your”). This Privacy Policy does not apply to the extent we process personal information in the role of a service provider or processor on behalf of our customers. By using our Services, you agree to the collection, use, disclosure, and procedures this Privacy Policy describes. Beyond the Privacy Policy, your use of our Services is also subject to our Terms of Service.</p>
<h3 id="information-we-collect">Information We Collect</h3>
<p>We may collect a variety of information from or about you or your devices from various sources, as described below.</p>
<h4 id="information-you-provide-to-us">Information You Provide to Us</h4>
<ul>
<li><p><strong>Registration Information.</strong> If you request information about or register to use our Services, we may ask you for your name, email address, password, mailing address, and the name, sector, and size of your organization. </p>
</li>
<li><p><strong>Communications.</strong> If you contact us directly, we may receive additional information about you. For example, if you contact us for customer support, we may receive your name, email address, the contents of the message and any attachments you send to us, and any other information you choose to provide. When we send you emails, we may track whether you open them to learn how to deliver a better customer experience and improve our Services</p>
</li>
<li><p><strong>Careers.</strong> If you decide that you wish to apply for a job with us, you may submit your contact information and your résumé online. We will collect the information you choose to provide on your resume, such as your education and employment experience. You may also apply through LinkedIn. If you do so, we will collect the information you make available to us on LinkedIn. </p>
</li>
<li><p><strong>Payment Information.</strong> If you make a purchase through our Services, your payment-related information, such as credit card or other financial information, is collected by our third party payment processor on our behalf. </p>
</li>
</ul>
<h4 id="information-we-collect-when-you-use-our-services">Information We Collect When You Use Our Services</h4>
<ul>
<li><p><strong>Device Information.</strong> We receive information about the device and software you use to access our Services, including your IP address (which may tell us your general location information), web browser type, operating system version, phone carrier and manufacturer, application installations, device identifiers, mobile advertising identifiers, and push notification tokens. </p>
</li>
<li><p><strong>Usage Information.</strong> To help us understand how you use our Services and to help us improve them, we automatically receive information about your interactions with our Services, such as the pages or other content you view, the searches you conduct, and the dates and times of your visits.</p>
</li>
<li><p><strong>Information from Cookies and Similar Technologies.</strong> We and third party partners collect information using cookies, pixel tags, or similar technologies. Our third party partners, such as analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services. Cookies are small text files containing a string of alphanumeric characters. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to our Services. Please review your web browser’s “Help” file to learn the proper way to modify your cookie settings. Please note that if you delete or choose not to accept cookies from the Service, you may not be able to utilize the features of the Service to their fullest potential.</p>
</li>
</ul>
<h4 id="information-we-receive-from-third-parties">Information We Receive from Third Parties</h4>
<ul>
<li><strong>Other third parties.</strong> We may receive additional information about you, such as demographic data, from third parties such as data or marketing partners and combine it with other information we have about you. </li>
</ul>
<h3 id="how-we-use-the-information-we-collect">How We Use the Information We Collect</h3>
<p>We may use the information we collect:</p>
<ul>
<li>To provide, maintain, improve, and enhance our Services;</li>
<li>To understand and analyze how you use our Services and develop new products, services, features, and functionality;</li>
<li>To communicate with you, provide you with updates and other information relating to our Services, provide information that you request, respond to comments and questions, and otherwise provide customer support;</li>
<li>For marketing and advertising purposes, such as developing and providing promotional and advertising materials that may be relevant, valuable or otherwise of interest to you;</li>
<li>To send you text messages;</li>
<li>To facilitate transactions and payments;</li>
<li>To find and prevent fraud, and respond to trust and safety issues that may arise; </li>
<li>For compliance purposes, including enforcing our Terms of Service or other legal rights, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency; and</li>
<li>For other purposes for which we provide specific notice at the time the information is collected.</li>
</ul>
<h3 id="how-we-share-the-information-we-collect">How We Share the Information We Collect</h3>
<p>We do not share or otherwise disclose information we collect from or about you except as described below or otherwise disclosed to you at the time of the collection.</p>
<ul>
<li><strong>Affiliates.</strong> We may share any information we receive with our affiliates for any of the purposes described in this Privacy Policy.</li>
<li><strong>Vendors and Service Providers.</strong> We may share any information we receive with vendors and service providers retained in connection with the provision of our Services. </li>
<li><strong>Analytics Partners.</strong> We use analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about your use of other websites, apps, and online resources. You can learn more about Google’s practices by visiting <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a>. To help us understand how you use our Services and to help us improve them, we automatically receive information about your interactions with our Services, like the pages or other content you view, the searches you conduct, and the dates and times of your visits.</li>
<li><strong>Advertising Partners.</strong> We work with third party advertising partners to show you ads that we think may interest you. Some of our advertising partners are members of the Network Advertising Initiative (<a href="https://optout.networkadvertising.org">https://optout.networkadvertising.org</a>) or the Digital Advertising Alliance (<a href="https://optout.aboutads.info">https://optout.aboutads.info</a>). If you do not wish to receive personalized ads, please visit their opt-out pages to learn about how you may opt out of receiving web-based personalized ads from member companies. You can access any settings offered by your mobile operating system to limit ad tracking, or you can install the AppChoices mobile app to learn more about how you may opt out of personalized ads in mobile apps.</li>
<li><strong>As Required By Law and Similar Disclosures.</strong> We may access, preserve, and disclose your information if we believe doing so is required or appropriate to: (a) comply with law enforcement requests and legal process, such as a court order or subpoena; (b) respond to your requests; or (c) protect your, our, or others’ rights, property, or safety. For the avoidance of doubt, the disclosure of your information may occur if you post any objectionable content on or through the Services.</li>
<li><strong>Merger, Sale, or Other Asset Transfers.</strong> We may transfer your information to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of our assets. The use of your information following any of these events will be governed by the provisions of this Privacy Policy in effect at the time the applicable information was collected.</li>
<li><strong>Consent.</strong> We may also disclose your information with your permission.</li>
</ul>
<h3 id="your-choices-preferences">Your Choices / Preferences</h3>
<p><strong>Marketing Communications.</strong> You can unsubscribe from our promotional emails via the link provided in the emails. Even if you opt out of receiving promotional messages from us, you will continue to receive administrative messages from us.</p>
<h3 id="third-parties">Third Parties</h3>
<p>Our Services may contain links to other websites, products, or services that we do not own or operate.  We are not responsible for the privacy practices of these third parties. Please be aware that this Privacy Policy does not apply to your activities on these third party services or any information you disclose to these third parties. We encourage you to read their privacy policies before providing any information to them.</p>
<h3 id="security">Security</h3>
<p>We make reasonable efforts to protect your information by using safeguards designed to improve the security of the information we maintain. However, as no electronic transmission or storage of information can be entirely secure, we can make no guarantees as to the security or privacy of your information.</p>
<h3 id="children">Children</h3>
<p>We do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no part of our Services is directed to children. If you learn that a child has provided us with personal information in violation of this Privacy Policy, then you may alert us at <a href="mailto:data-deletion@threatkey.com">data-deletion@threatkey.com</a>.</p>
<h3 id="international-transfers-of-data">International Transfers of Data</h3>
<p>Our Services are hosted in the United States and intended for visitors located within the United States. If you choose to use the Services from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing. Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the Services. By providing any information, including personal information, on or to the Services, you consent to such transfer, storage, and processing.</p>
<h3 id="accessing-updating-your-information">Accessing / Updating Your Information</h3>
<p>You can update your account and profile information through your profile settings. To close your account or have your information deleted, contact <a href="mailto:data-deletion@threatkey.com">data-deletion@threatkey.com</a>.</p>
<h3 id="changes-to-this-privacy-policy">Changes to This Privacy Policy</h3>
<p>We will post any adjustments to the Privacy Policy on this page, and the revised version will be effective when it is posted. If we materially change the ways in which we use or share personal information previously collected from you through the Services, we will notify you through the Services, by email, or other communication.</p>
<h3 id="contact-us">Contact Us</h3>
<p>ThreatKey is responsible for processing your information. If you have any questions, comments, or concerns about our processing activities, please email us at <a href="mailto:support@threatkey.com">support@threatkey.com</a> or write to us at 228 Park Avenue South, PMB 52129, New York, New York 10003-1502.</p>
          </div>
        </GenericSection>
        <Cta topDivider className="illustration-section-06" />
      </React.Fragment>
    );
  }
}

// inline style

export default PrivacyPolicy;
