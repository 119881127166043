import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Team extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'team-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Meet ThreatKey',
      paragraph: 'We\'re a small team of passionate technologists dedicated to understanding and reducing cybersecurity risk.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>

            <div className="tiles-item reveal-from-top">
                <div className="tiles-item-inner">
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Carlos Beltran
                    </h5>
                    <div className="team-item-role text-xxs tt-u text-color-primary mb-8">
                      CTO & Co-Founder
                    </div>
                    <p className="m-0 text-sm">
                      Los Angeles, CA
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-top">
                <div className="tiles-item-inner">
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Jonathan Haas
                    </h5>
                    <div className="team-item-role text-xxs tt-u text-color-primary mb-8">
                      CEO & Co-Founder
                    </div>
                    <p className="m-0 text-sm">
                       New York, NY
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Benjamin Small, PhD
                    </h5>
                    <div className="team-item-role text-xxs tt-u text-color-primary mb-8">
                      CDO & Co-Founder
                    </div>
                    <p className="m-0 text-sm">
                      London, UK
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
